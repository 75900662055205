<template>
  <div class="bg" v-if="isWeb">
    <div class="logo_img">
      <a href="#">
        <img width="96" height="27" alt="" src="../assets/download/logo.png"/>
      </a>
    </div>
    <div class="center">
      <div class="title">IT中心软件下载中心</div>
      <div class="one">
        <div class="titOne">APP软件</div>
        <div class="icon">
          <div class="icondiv">
            <img class="iconapp" width="72" height="72" alt="" src="../assets/download/52car.png"/>
            <p class="titp">52车</p>
            <p class="dow" @click="download('52车')">点击下载 <img class="jt" width="18" height="13" alt="" src="../assets/download/icon_arrow.png"/></p>
          </div>
          <div class="icondiv">
            <img class="iconapp" width="72" height="72" alt="" src="../assets/download/52business.png"/>
            <p class="titp">52车商家端</p>
            <p class="dow" @click="download('52车商家端')">点击下载 <img class="jt" width="18" height="13" alt="" src="../assets/download/icon_arrow.png"/></p>
          </div>
          <div class="icondiv">
            <img class="iconapp" width="72" height="72" alt="" src="../assets/download/xiaoshouzhushou.png"/>
            <p class="titp">销售助手</p>
            <p class="dow" @click="download('销售助手')">点击下载 <img class="jt" width="18" height="13" alt="" src="../assets/download/icon_arrow.png"/></p>
          </div>
          <div class="icondiv">
            <img class="iconapp" width="72" height="72" alt="" src="../assets/download/xibao.png"/>
            <p class="titp">喜宝</p>
            <p class="dow" @click="download('喜宝')">点击下载 <img class="jt" width="18" height="13" alt="" src="../assets/download/icon_arrow.png"/></p>
          </div>
          <div class="icondiv">
            <img class="iconapp" width="72" height="72" alt="" src="../assets/download/wuxing.png"/>
            <p class="titp">无形</p>
            <p class="dow" @click="download('无形')">点击下载 <img class="jt" width="18" height="13" alt="" src="../assets/download/icon_arrow.png"/></p>
          </div>
        </div>
      </div>
      <div class="two">
        <div class="two-left">
          <div class="titTwo">微信小程序</div>
          <div class="iconx">
            <div class="xiaochengxu">
              <div class="showone">
                <img class="iconapp" width="72" height="72" alt="" src="../assets/download/xidi.png"/>
                <p class="titp">喜滴司服</p>
              </div>
              <div class="showtwo">
                <img class="iconappm" width="140" height="140" alt="" src="../assets/download/xidixiaochengxu.png"/>
                <p class="titptwo">打开微信扫一扫</p>
              </div>
            </div>
          </div>
        </div>
        <div class="two-right">
          <div class="titTwo">支付宝小程序</div>

          <div class="iconx">
            <div class="xiaochengxu">
              <div class="showone">
                <img class="iconapp" width="72" height="72" alt="" src="../assets/download/kuaiya.png"/>
                <p class="titp">快呀</p>
              </div>

              <div class="showtwo">
                <img class="iconappm" width="140" height="140" alt="" src="../assets/download/kuaiyaxiaochengxu.png"/>
                <p class="titptwo">打开支付宝扫一扫</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>

    <div v-show="show" class="modal">
      <div class="modal-content">
        <img class="close_img" width="14" height="14" alt="" src="../assets/download/close.png"  @click="close"/>
        <p class="modal-title"><span>{{app}}</span>APP下载</p>
        <div class="modal-img">
          <div class="modal-imgone">
            <div class="modal-tit"><img width="24" height="24" alt="" src="../assets/download/icon_android.png"/>iphone版</div>
            <img class="QRcode" width="200" height="200" alt="" :src="imgone"/>
          </div>
          <div class="modal-imgtwo">
            <div class="modal-tit"><img width="24" height="24" alt="" src="../assets/download/icon_ios.png"/>Android版</div>
            <img class="QRcode" width="200" height="200" alt="" :src="imgtwo"/>
          </div>
        </div>
      </div>
    </div>

  </div>


  <div v-else class="appbg">
    <title class="title-background">IT中心软件下载中心</title>
    <div class="app_div">
      <div class="app_one">
        <div class="app_title">APP软件</div>
        <div class="app_icon_center mbottom">
          <div class="child" @click="downloadapp('52车')">
            <img class="iconapp" width="130" height="130" alt="" src="../assets/download/52car.png"/>
            <p class="app_titp">52车</p>
          </div>
          <div class="child zhong" @click="downloadapp('52车商家端')">
            <img class="iconapp" width="130" height="130" alt="" src="../assets/download/52business.png"/>
            <p class="app_titp">52车商家端</p>
          </div>
          <div class="child" @click="downloadapp('销售助手')">
            <img class="iconapp" width="130" height="130" alt="" src="../assets/download/xiaoshouzhushou.png"/>
            <p class="app_titp">销售助手</p>
          </div>
        </div>
        <div class="app_icon_center">
          <div class="child" @click="downloadapp('喜宝')">
            <img class="iconapp" width="130" height="130" alt="" src="../assets/download/xibao.png"/>
            <p class="app_titp">喜宝</p>
          </div>
          <div class="child zhong" @click="downloadapp('无形')">
            <img class="iconapp" width="130" height="130" alt="" src="../assets/download/wuxing.png"/>
            <p class="app_titp">无形</p>
          </div>
        </div>
      </div>
      <div class="app_two">
        <div class="app_title">微信小程序</div>
        <div class="app_icon_center" @click="code('xidi')">
          <div class="child">
            <img class="iconapp" width="130" height="130" alt="" src="../assets/download/xidi.png"/>
            <p class="app_titp">喜滴司服</p>
          </div>
        </div>
      </div>
      <div class="app_two">
        <div class="app_title">支付宝小程序</div>
        <div class="app_icon_center" @click="code('kuaiya')">
          <div class="child">
            <img class="iconapp" width="130" height="130" alt="" src="../assets/download/kuaiya.png"/>
            <p class="app_titp">快呀</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "Download",
  data() {
    return {
      show:false,
      app:'',
      imgone:'',
      imgtwo:'',
      isWeb:true,
      userAgentInfo:''
    };
  },

  mounted() {
    const mq = window.matchMedia("(max-width: 768px)");
    if (mq.matches) {
      document.title = "IT中心软件下载中心";
    } else {
      document.title = "IT中心软件下载中心";
    }
  },
  created() {
    var userAgentInfo = navigator.userAgent; 
    // 判断是否为移动端（包括iOS、Android等）
    if (/Android/i.test(userAgentInfo)) {
      this.isWeb = false
        console.log("这是Android");
        this.userAgentInfo = 'android'
    } else if (/iPhone|iPad|iPod/i.test(userAgentInfo)) {
      this.isWeb = false
        console.log("这是iOS");
        this.userAgentInfo = 'ios'
    } else {
      this.isWeb = true
        console.log("这是web");
        this.userAgentInfo = 'web'
    }
  },
  methods: {

    download(e) {
      console.log('e=',e)
      this.app=e
      if(e=="52车"){
        this.imgone=require('../assets/download/test/52carIos.png')
        this.imgtwo=require('../assets/download/test/52carAndroid.png')
      }else if(e=="52车商家端"){
        this.imgone=require('../assets/download/test/52business_ios.png')
        this.imgtwo=require('../assets/download/test/52business_android.png')
      }else if(e=="销售助手"){
        this.imgone=require('../assets/download/test/xiaoshouzhushou_ios.png')
        this.imgtwo=require('../assets/download/test/xiaoshouzhushou_android.png')
      }else if(e=="喜宝"){
        this.imgone=require('../assets/download/test/xibao_ios.png')
        this.imgtwo=require('../assets/download/test/xibao_android.png')
      }else{
        this.imgone=require('../assets/download/test/wuxing_ios.png')
        this.imgtwo=require('../assets/download/test/wuxing_android.png')
      }
      this.show=true
    },
    close(){
      this.show=false
    },
    downloadapp(e){
      //window.open()方法中的第二个参数'_blank'用于指定在新窗口或标签页中打开链接。如果你想在当前窗口打开链接，可以省略这个参数或者将其设置为'_self'。
      var url=''
      if(this.userAgentInfo =='android'){
        //安卓
        if(e=="52车"){
          url = 'http://devapp.xxfcar.com/fj5t'
        }else if(e=='52车商家端'){
          url = 'http://devapp.xxfcar.com/16vh'
        }else if(e=='销售助手'){
          url= 'http://devapp.xxfcar.com/4jbk'
        }else if(e=='喜宝'){
          url = 'http://devapp.xxfcar.com/kx2b'
        }else{
          url = 'http://devapp.xxfcar.com/tdwm'
        }
      }else{
        //ios
        if(e=="52车"){
          url = 'http://devapp.xxfcar.com/3wlv'
        }else if(e=='52车商家端'){
          url = 'http://devapp.xxfcar.com/q124'
        }else if(e=='销售助手'){
          url= 'http://devapp.xxfcar.com/qn29'
        }else if(e=='喜宝'){
          url = 'http://devapp.xxfcar.com/8w7b'
        }else{
          url = 'http://devapp.xxfcar.com/cey4'
        }
      }
      window.open(url, '_blank');
    },
    code(e){
      this.$router.push({
        path: "/Code",
        query: { qr: e }
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.bg {
  width: 100%;
  min-width: 1200px;
  height: 100%;
  background: url("../assets/download/bg.png");
  background-size: 100% 100%;
  position:relative;
}
.logo_img{
  position:absolute;
  padding-top:16px;
  padding-left:40px;
}
.center{
  text-align:center;
  width:1000px;
}
.title{
  font-size:44px;
  color:#071144;
  padding-top:129px;
  padding-bottom:34px;
}
.one{
  background:rgba(255, 255, 255, 0.50);
  border-radius:18px;
  border:1px solid #FFFFFF;
  width:100%;
  height:266px;
  margin-bottom:20px;
  text-align:left;
}
.two{
  width:100%;
  display: flex; /* 创建flex容器 */
  justify-content: space-between;
  text-align:left;
}
.two-left{
  background:rgba(255, 255, 255, 0.50);
  border-radius:18px;
  border:1px solid #FFFFFF;
  width:490px;
  height:266px;
}
.two-right{
  background:rgba(255, 255, 255, 0.50);
  border-radius:18px;
  border:1px solid #FFFFFF;
  width:490px;
  height:266px;
}
.titOne{
  font-size:24px;
  color:#071144;
  padding-top:40px;
  padding-left:46px;
}
.titTwo{
  font-size:22px;
  color:#071144;
  padding-top:40px;
  padding-left:46px; 
}
.icon{
  display: flex; /* 创建flex容器 */
  padding:12px;
}
.icondiv{
  width:132px;
  text-align:center;
  cursor: pointer;
}
.icondiv .iconapp{
  padding-top:28px;
}
.titp{
  font-size:16px;
  color:#071144;
  padding-top:3px;
  padding-bottom:3px;
  text-align:center;
}
.dow{
  font-size:16px;
  color:#FFFFFF;
  background:#2B89FF;
  border-radius:0 0 13px 13px;
  height:38px;
  line-height:38px;
  text-align:left;
  padding-left:14px;
  display:none;
}
.icondiv:hover {
  background-color: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0,36,94,0.15);
  border-radius: 13px;
}
.icondiv:hover .dow {
	display: block;
}
.jt{
  padding-left:22px;
}
.iconx{
  display: flex; /* 创建flex容器 */
  padding:12px 0;
}
.xiaochengxu{
  width:132px;
  text-align:center;
  cursor: pointer;
}
.xiaochengxu .iconapp{
  padding-top:28px;
}
.showone{
  display:block;
}
.showtwo{
  width: 164px;
  height: 182px;
  background: #FFFFFF;
  border-radius: 13px;
  display:none;
}
.titptwo{
  font-size:14px;
  color:#071144;
}
.xiaochengxu:hover .showone {
  display: none;
}
.xiaochengxu:hover .showtwo {
	display: block;
}
.iconappm{
  padding:5px 12px 0 12px;
}
.modal {
  position: fixed; /* 位置固定 */
  z-index: 1; /* 在其他内容之上 */
  padding-top: 100px; /* 距离顶部的距离 */
  left: 0;
  top: 0;
  width: 100%; /* 宽度全屏 */
  height: 100%; /* 高度全屏 */
  overflow: auto; /* 可滚动 */
  background-color: rgba(0, 2, 29, 0.4); /* 背景色带透明度 */
}
.modal-content{
  width: 488px;
  height: 340px;
  background: url("../assets/download/pic_top bg.png");
  background-size: 100% 100%;
  position:absolute;
  left: 50%;  /* 将子 div 的左边移到父 div 的中间 */
  top:50%;
  transform: translate(-50%,-50%);  /* 将子 div 向左移动自身宽度的50%以居中 */
}
.close_img{
  position:absolute;
  right:30px;
  top:29px;
}
.modal-title{
  font-size:18px;
  color:#071144;
  padding-top:20px;
  padding-left:27px;
  font-weight:600;
}
.modal-img{
  display: flex; /* 创建flex容器 */
  justify-content: space-between;
  padding:30px;
}
.modal-tit{
  font-size:16px;
  color:#071144;
  display: flex;
  align-items: center;
  justify-content: center;
}
.QRcode{
  border-radius:10px
}
.modal-imgone{

}
.modal-imgtwo{
  
}
// app
.appbg{
  width: 100%;
  height: 100%;
  background: url("../assets/download/app_bg.png");
  background-size: 100% 100%;
}
.app_div{
  padding: 20px 30px;
}
.app_one{
  margin-top:20px;
  width:100%;
  background:rgba(255, 255, 255, 0.50);
  border-radius:16px;
  border:1px solid #FFFFFF;
  height:574px;
}
.app_two{
  margin-top:20px;
  width:100%;
  background:rgba(255, 255, 255, 0.50);
  border-radius:16px;
  border:1px solid #FFFFFF;
  height:344px;
}
.app_title{
  font-size:36px;
  color:#071144;
  font-weight:700;
  margin:40px 0 6px 40px;
}
.app_icon_center{
  margin:32px 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; /* 子元素居左对齐 */
}
.child {
text-align:center;
}
.zhong{
  margin:0 216px;
}
.mbottom{
  margin-bottom:48px
}
.app_titp{
  font-size:28px;
  color:#071144;
}
</style>
